<template>
  <b-tabs pills>
    <b-tab active>
      <template #title>
        <feather-icon icon="MessageSquareIcon" />
        <span>Hatırlatma Notu</span>
      </template>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <customer-card
            v-if="alert.id_com_customer"
            :id_com_customer="alert.id_com_customer"
          />
        </b-col>
        <b-col>
          <b-card title="Hatırlatma">
            <b-list-group>
              <b-list-group-item>
                <div class="text-primary font-weight-bolder">
                  Hatırlatma Tarihi
                </div>
                <div>
                  {{ alert.alert_date? moment(alert.alert_date).format('DD.MM.YYYY') : '-' }}
                </div>
              </b-list-group-item>
              <b-list-group-item>
                <div class="text-primary font-weight-bolder">
                  Hatırlatma Notu
                </div>
                <div v-html="alert.alert_notes" />
              </b-list-group-item>
              <b-list-group-item>
                <div class="text-primary font-weight-bolder">
                  Hatırlatma Durumu
                </div>
                <b-form-checkbox
                  v-model="alert.alert_status"
                  value="0"
                  switch
                  @change="changeStatus"
                >
                  <span
                    v-if="alert.alert_status !== '1'"
                    class="text-danger"
                  >
                    Beklemede <span class="text-muted">(Açık)</span>
                  </span>
                  <span
                    v-else
                    class="text-success"
                  >
                    Tamamlandı <span class="text-muted">(Kapalı)</span>
                  </span>
                </b-form-checkbox>
              </b-list-group-item>
            </b-list-group>
            <b-button
              v-if="alert.interview_number"
              variant="danger"
              pill
              class="mt-1"
              :to="'/interviews/view/' + alert.interview_number"
            >
              Görüşme Notuna Git
              <FeatherIcon icon="ChevronRightIcon" />
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="UsersIcon" />
        <span>Müşteri Geçmişi</span>
      </template>
      <customer-view />
    </b-tab>
  </b-tabs>
</template>
<script>
import {
  BRow, BCol, BCard, BListGroup, BListGroupItem, BButton, BTab, BTabs, BFormCheckbox,
} from 'bootstrap-vue'
import CustomerCard from '@/views/Alerts/components/CustomerCard.vue'
import CustomerView from '@/views/Customers/CustomerView.vue'

export default {
  name: 'Add',
  components: {
    CustomerView,
    BTab,
    BRow,
    BCol,
    BCard,
    BTabs,
    BListGroup,
    BListGroupItem,
    BButton,
    BFormCheckbox,
    CustomerCard,
  },
  computed: {
    alert() {
      return this.$store.getters['alerts/getAlert']
    },
  },
  created() {
    this.getAlert()
  },
  methods: {
    getAlert() {
      this.$store.dispatch('alerts/alertView', this.$route.params.id)
    },
    changeStatus() {
      let alertStatus = '1'
      if (this.alert.alert_status === '1') {
        alertStatus = '1'
      }
      if (this.alert.alert_status === '0') {
        alertStatus = '0'
      }
      this.$store.dispatch('alerts/alertSave', {
        id: this.alert.id,
        alert_status: alertStatus,
      })
      this.getAlert()
    },
  },
}
</script>

<style scoped>

</style>
